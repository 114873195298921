.App {
  text-align: center;
}

.appbar {
  background-color: rgba(255, 255, 255, 0.400);
}


body {
  background-color: white;
  margin: 0;
}

.card-grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1rem;
}

.card {
  font-family: "louis";
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 0.55rem;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .5);
  background-color: white;
  transform-style: preserve-3d;
  height: 500px;
  transition: 200ms;
  cursor: pointer;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0))
  translateY(var(--translate-y, 0));
}

.card:hover {
  --translate-y: -2px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0 , .8);
}

.card.flip {
  --rotate-y: 180deg;
}

.card .front {
  left: 0;
}

.card .front,
.card .back {
  position: absolute;
  padding: 1rem;
  backface-visibility: hidden;
}

.card .back {
  transform: rotateY(180deg);
}

.moving {
  font-size: 50px;
  color: rgba(0,0,0);
  letter-spacing: 5px;
  -webkit-text-stroke-width: 0.7px;
  position: static;
  background-position: 0 0;
  background-attachment: fixed;
}

.contact {
  display: flex;
  justify-content: center;
}

.connect {
  display: flex;
  justify-content: center;
}

.folder1 {
  cursor: pointer;
}
.folder1:hover {
  transform: scale(1.09);
}

.folder2 {
  cursor: pointer;
}
.folder2:hover {
  transform: scale(1.09);
}

.folder3 {
  cursor: pointer;
}
.folder3:hover {
  transform: scale(1.09);
}

.graphics {
  justify-content: center;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax 1000px, 1fr);
  margin-top: 50 px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pic {
  display: flex;
  justify-content: center;
}

.Picture {
  display: flex;
  justify-content: center;
}

.work {
  display: flex;
  justify-content: center;
}
